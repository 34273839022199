import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '../translate.service';
import {Injectable} from '@angular/core';

@Injectable()
export class EdkMatPaginatorIntl extends MatPaginatorIntl {
    constructor(private translateService: TranslateService) {
        super();
        this.init();
    }

    private ofLabel = 'of';

    private init() {
        this.translateService.lang$.subscribe(lang => {
            this.itemsPerPageLabel = this.translateService.tr('paginatorItemsPerPage');
            this.nextPageLabel = this.translateService.tr('paginatorNext');
            this.previousPageLabel = this.translateService.tr('paginatorPrev');
            this.ofLabel = this.translateService.tr('paginatorOf');
        });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.ofLabel} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.ofLabel } ${length}`;
    }

}
