import {Observable} from 'rxjs/Observable';
import {ResourceIdName} from './user-access';

export interface DashboardInfo {
    showNewAreaRequests: boolean;
    newAreaRequests: number;
    routesForApproval: number;
    routesRejected: number;
    allRoutes: number;
    allAreas: number;
    areasForActivation: number;
    areas: ResourceIdName[];
    areaGroups: ResourceIdName[];
}

export abstract class DashboardInfoProvider {
    abstract getDashboardForLoggedUser(editionId: string): Observable<DashboardInfo>;
}