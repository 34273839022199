import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthService, OAuthStorage} from 'angular-oauth2-oidc';
import 'rxjs/add/operator/catch';
import {_throw} from 'rxjs-compat/observable/throw';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authStorage: OAuthStorage,
        private errorHandler: OAuthResourceServerErrorHandler,
        private oauthService: OAuthService,
        @Optional() private moduleConfig: OAuthModuleConfig) {
    }

    private checkUrl(url: string): boolean {
        const found = this.moduleConfig.resourceServer.allowedUrls.find(u => url.startsWith(u));
        return !!found;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url.toLowerCase();
        if (!this.moduleConfig || !this.moduleConfig.resourceServer || !this.moduleConfig.resourceServer.allowedUrls
            || !this.checkUrl(url)) {
            return next.handle(req);
        }

        if (!this.oauthService.hasValidAccessToken()) {
            return next.handle(req);
        }

        if (this.moduleConfig.resourceServer.sendAccessToken) {

            const token = this.authStorage.getItem('access_token');
            if (!token) {
                return next.handle(req);
            }
            const header = 'Bearer ' + token;

            const headers = req.headers.set('Authorization', header);

            req = req.clone({headers});
        }

        return next.handle(req).catch(err => this.errorHandler.handleError(err));

    }
}

export class OAuthNoopResourceServerErrorHandler implements OAuthResourceServerErrorHandler {

    handleError(err: HttpResponse<any>): Observable<any> {
        return _throw(err);
    }

}
