import {Pipe, PipeTransform} from '@angular/core';

export const ROMAN_NUMERALS = {
    0: '0',
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
    5: 'V',
    6: 'VI',
    7: 'VII',
    8: 'VIII',
    9: 'IX',
    10: 'X',
    11: 'XI',
    12: 'XII',
    13: 'XIII',
    14: 'XIV',
    15: 'XV',
    16: 'XVI'
};

@Pipe({
    name: 'roman',
})
export class RomanNumeralsPipePipe implements PipeTransform {
    transform(input: number): string {
        if (input === undefined || input === null) {
            return ''
        }
        return ROMAN_NUMERALS[input];
    }

}