<div class="header-container">
    <div class="logo-container">
        <a class="logo" [routerLink]="['/edk-pages/home']"><img src="assets/img/logo.png" alt="e-DK Panel" class="logo-symbol"></a>
    </div>
    <div class="pr-4 font-weight-bold" *ngIf="currentEdition && currentEdition.id">{{'edition' | msg}} {{currentEdition.name}}</div>
</div>

<div class="header-container">
    <nb-actions size="medium">

        <nb-action (click)="login()" title="{{'logInBtn' | msg}}">
            <nb-icon icon="power-outline"></nb-icon>
        </nb-action>

    </nb-actions>
</div>
