import {Observable} from 'rxjs';

export interface ActivityLog {
    areaId: string;
    areaRequestId: string;
    area: string;
    areaGroup: string;
    event: string;
    eventTime: string;
}

export abstract class LatestActivityProvider {
    abstract findActivities(editionId: string, areaGroupId: string): Observable<ActivityLog[]>;
}
