import {Observable} from 'rxjs';

export interface Territory {
    id: string;
    editionId: string;
    name: string;
    locale: string;
    countries: string[];
}

export abstract class TerritoryCrud {
    abstract findTerritory(id: string): Observable<Territory>;
    abstract findTerritories(editionId: string): Observable<Territory[]>;
}

