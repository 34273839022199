<div *ngIf="editions && editions.length">
    <div *ngIf="!showSelector" class="edition-selector-label" (click)="enableSelector()" title="{{'editionStatus_' + selectedEdition.edition.status | msg}}">
        {{'edition' | msg}} {{selectedEdition.edition.name}}
        <span *ngIf="selectedEdition.edition.status !== ACTIVE"> - {{'editionStatus_' + selectedEdition.edition.status | msg}}</span>
    </div>
    <div *ngIf="showSelector && selectedEdition">
        <nb-select
                class="edition-selector-dropdown"
                matRipple
                [selected]="selectedEdition.edition.id"
                (selectedChange)="switchEdition($event)">
            <nb-option
                    *ngFor="let re of editions"
                    [value]="re.edition.id"
                    matRipple>{{label(re)}}</nb-option>
        </nb-select>
    </div>
</div>