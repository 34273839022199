import {Observable} from 'rxjs';

export const personTitles: string[] = ['MR', 'MRS', 'PRIEST', 'SISTER', 'FATHER', 'BROTHER'];

export interface UserDetails {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
    lang: string;
    accessMap: UserAccessMap;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    lang: string;
    title: string;
    addons?: any;
}

export interface UserAccessMap {
    accesses: [string, [string, string[]]];
    globalAccesses: string[];
}

export interface LeadersForEditions {
    editions: string[];
    users: User[];
}

export interface AllUsersPage {
    total: number;
    data: User[];
}

export abstract class UserCrud {
    abstract loggedUserDetails(): Observable<UserDetails>;
    abstract findAllUsers(page: number, pageSize: number, q: string): Observable<AllUsersPage>;
    abstract findUserWithResources(userId: string, editionId: string): Observable<User>;
    abstract deleteUser(userId: string): Observable<any>;
}

export abstract class LoggedUserUpdater {
    abstract getLoggedUser(): Observable<User>;
    abstract update(user: User): Observable<User>;
    abstract updatePassword(): Observable<any>;
}

export abstract class LeadersProvider {
    abstract findLeaders(editionId: string): Observable<LeadersForEditions>;
}
