import {Observable} from 'rxjs';

export namespace RouteGuideStatus {
    export const DRAFT = 'DRAFT';
    export const READY = 'READY';
}

export interface GuideStation {
    index: number;
    content: string;
}

export interface GuideStep {
    index: number;
    content: string;
}
export interface GuideSection {
    index: number;
    startStep: number;
    steps: GuideStep[];
}

export interface RouteGuide {
    id: string;
    routeId: string;
    lang: string;
    updateDate: string;
    additionalInfo: string;
    status: string;
    stations: GuideStation[];
    interstations: GuideSection[];
}

export abstract class RouteGuideCrud {
    abstract findByRoute(routeId: string): Observable<RouteGuide[]>;
    abstract update(guide: RouteGuide);
    abstract create(guide: RouteGuide);
    abstract deleteRouteGuide(id: string): Observable<any>;
}