import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {RouteGuide, RouteGuideCrud} from '../data/edk/route-guide';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable()
export class RouteGuideCrudService extends RouteGuideCrud {
    constructor(private http: HttpClient) {
        super();
    }

    findByRoute(routeId: string): Observable<RouteGuide[]> {
        return this.http.get<RouteGuide[]>(API_URL + '/api/v1/route-guide/by-route/' + routeId);
    }

    update(guide: RouteGuide) {
        return this.http.put<RouteGuide>(API_URL + '/api/v1/route-guide', guide);
    }

    create(guide: RouteGuide) {
        return this.http.post<RouteGuide>(API_URL + '/api/v1/route-guide', guide);
    }

    deleteRouteGuide(id: string): Observable<any> {
        return this.http.delete<any>(API_URL + '/api/v1/route-guide/' + id);
    }

}