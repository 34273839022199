import {DashboardInfo, DashboardInfoProvider} from '../data/edk/dashboard';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable()
export class DashboardInfoProviderImpl extends DashboardInfoProvider {
    constructor(private http: HttpClient) {
        super();
    }
    getDashboardForLoggedUser(editionId: string): Observable<DashboardInfo> {
        return this.http.get<DashboardInfo>(API_URL + '/api/v1/dashboard', {params: {editionId: editionId}});
    }

}