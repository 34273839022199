import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {LayoutService} from '../../../@core/utils';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {AuthService} from '../../../auth/auth.service';
import {UserDetails} from '../../../@core/data/edk/user';
import {CurrentResource, CurrentResourceService} from '../../../@core/data/edk/current-resource';
import {CurrentEditionService, Edition} from '../../../@core/data/edk/edition';
import {ResourceType} from '../../../@core/data/edk/resource-type';
import {TranslateService} from '../../../@core/lang/translate.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['header.component.scss'],
    templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>();
    public readonly materialTheme$: Observable<boolean>;
    userPictureOnly: boolean = false;

    @Input()
    sideMenuVisible: boolean = true;

    userDetails$: Observable<UserDetails>;
    areaGroupsVisible: boolean = false;
    areasVisible: boolean = false;
    routesVisible: boolean = false;
    adminVisible: boolean = false;

    currentResource: CurrentResource;
    currentEdition: Edition;
    isAuthenticated: boolean = false;
    userMenu = [];

    public constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService,
        private currentResourceService: CurrentResourceService,
        private currentEditionService: CurrentEditionService,
        private authService: AuthService,
        private tr: TranslateService,
    ) {
        this.currentEditionService.init();
        this.userMenu = [
            {title: this.tr.tr('profile'), link: '/edk-pages/my-profile', data: 'profile'},
            {title: this.tr.tr('changePassword'), link: '/edk-pages/my-password', data: 'changePassword'},
            {title: this.tr.tr('contracts'), link: '/edk-pages/my-contracts', data: 'contracts'},
            {title: this.tr.tr('logOut'), data: 'logout'}];

        this.materialTheme$ = this.themeService.onThemeChange()
            .pipe(map(theme => {
                const themeName: string = theme?.name || '';
                return themeName.startsWith('material');
            }));
        this.authService.login();
    }

    ngOnInit() {
        const {xl} = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.authService.authenticationEventObservable.subscribe(logged => {
            if (logged) {
                this.isAuthenticated = true;
            }
        });
        this.userDetails$ = this.authService.loggedUser$;
        this.userDetails$
            .pipe(filter(userDetails => userDetails && true))
            .subscribe(userDetails => {
            this.areaGroupsVisible = this.hasAnyAccessForResourceType(userDetails, ResourceType.AREA_GROUP);
            this.areasVisible = this.hasAnyAccessForResourceType(userDetails, ResourceType.AREA);
            this.routesVisible = this.hasAnyAccessForResourceType(userDetails, ResourceType.ROUTE);
            this.adminVisible = this.isGlobalAdmin(userDetails);
        });

        this.currentResourceService.getCurrentResource()
            .pipe(
                map(({currentResource}) => currentResource),
                takeUntil(this.destroy$),
            ).subscribe(res => {
            this.currentResource = res;
        });
        this.currentEditionService.currentEdition().subscribe(edition => this.currentEdition = edition);

        this.menuService.onItemClick()
            .pipe(
                filter(({tag}) => tag === 'logged-user'),
                map(({item: {data}}) => data),
            )
            .subscribe(data => {
                if (data === 'logout' && this.isAuthenticated) {
                    this.authService.logout();
                    this.isAuthenticated = false;
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    private hasAnyAccessForResourceType(userDetails: UserDetails, resourceType: string): boolean {
        return userDetails.accessMap
            && ((userDetails.accessMap.globalAccesses && userDetails.accessMap.globalAccesses.length > 0)
                || (userDetails.accessMap.accesses
                    && userDetails.accessMap.accesses[resourceType]
                    && Object.keys(userDetails.accessMap.accesses[resourceType]).length > 0));
    }

    private isGlobalAdmin(userDetails: UserDetails) {
        return userDetails.accessMap
            && userDetails.accessMap.globalAccesses && userDetails.accessMap.globalAccesses.length > 0
            && userDetails.accessMap.globalAccesses.includes('ADMIN');
    }
}
