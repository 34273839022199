import {Observable} from 'rxjs';

export interface UserName {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
}

export interface UserAccesses {
    user: User;
    resAvailableAccesses: string[];
    resAccesses: ResourceWithAccesses;
    subResType: string;
    subResAvailableAccesses: string[];
    subResAccesses: ResourceWithAccesses[];
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    title: string;
}

export interface UsersPage {
    total: number;
    data: User[];
}

export interface ResourceWithAccesses {
    resourceId: string;
    resourceName: string;
    accesses: string[];
    comment: string;
}

export interface ResourceIdName {
    id: string;
    name: string;
}

export interface ResourceIdNames {
    AREA_GROUP: ResourceIdName[];
    AREA: ResourceIdName[];
    ROUTE: ResourceIdName[];
}

export interface UserResource {
    userId: string;
    resourceId: string;
    resourceType: string;
}

export interface TeamMember {
    user: User;
    resAccesses: string[];
    parentResAccesses: string[];
    resComment: string;
    parentComment: string;
}

export interface UpdateUserAccessCommand {
    editionId: string;
    userId: string;
    resourceId: string;
    resourceType: string;
    access: string;
    action: string;
}

export interface CreateUserForResourceCommand {
    editionId: string;
    resourceId: string;
    resourceType: string;
    lastName: string;
    firstName: string;
    email: string;
    phone: string;
    lang: string;
    title: string;
}

export interface UpdateUserAccessCommentCommand {
    editionId: string;
    resourceId: string;
    resourceType: string;
    userId: string;
    comment: string;
}

export abstract class UserAccessesCrud {
    abstract findUsers(page: number, pageSize: number, q: string,
                       resourceType: string, resourceId: string): Observable<UsersPage>;

    abstract findUserNames(resourceType: string, resourceId: string, q: string): Observable<UserName[]>;

    abstract findUserAccesses(userId: string, resourceType: string, resourceId: string): Observable<UserAccesses>;

    abstract setUpdatingAccessInProgress(inProgress: boolean);
    abstract updateUserAccess(command: UpdateUserAccessCommand): Observable<any>;
    abstract getUpdatingAccessInProgress(): boolean;

    abstract createUserForResource(command: CreateUserForResourceCommand): Observable<User>;
    abstract findTeamMembers(resourceType: string, resourceId: string): Observable<TeamMember[]>;
    abstract updateUserAccessComment(command: UpdateUserAccessCommentCommand): Observable<any>;
}
