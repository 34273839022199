import {AreaGroup, AreaGroupCrud, AreaGroupPage} from '../data/edk/area-group';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

const API_URL = environment.apiUrl;

@Injectable()
export class AreaGroupCrudService extends AreaGroupCrud {
    constructor(private http: HttpClient) {
        super();
    }

    create(areaGroup: AreaGroup): Observable<AreaGroup> {
        return this.http.post<AreaGroup>(API_URL + '/api/v1/area-group', areaGroup);
    }

    update(areaGroup: AreaGroup): Observable<AreaGroup> {
        return this.http.put<AreaGroup>(API_URL + '/api/v1/area-group', areaGroup);
    }

    findAreaGroup(id: string): Observable<AreaGroup> {
        return this.http.get<AreaGroup>(API_URL + '/api/v1/area-group/' + id);
    }

    findAreaGroups(editionId: string, page: number, pageSize: number, q: string): Observable<AreaGroupPage> {
        return this.http.get<AreaGroupPage>(API_URL + '/api/v1/area-group', {
            params: {
                page: page,
                pageSize: pageSize,
                editionId: editionId,
                q: q,
            }});
    }

    findAreaGroupsAsOptions(editionId: string): Observable<any[]> {
        return this.http.get<any[]>(API_URL + '/api/v1/area-group/all/options', {
            params: {
                editionId: editionId,
            }});
    }



}
