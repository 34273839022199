import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';
import {
  NbActionsModule,
  NbButtonModule,
  NbContextMenuModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

import {FooterComponent, HeaderComponent, SearchInputComponent, TinyMCEComponent,} from './components';
import {CapitalizePipe, NumberWithCommasPipe, PluralPipe, RoundPipe, TimingPipe,} from './pipes';
import {OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent,} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
import {COSMIC_THEME} from './styles/theme.cosmic';
import {CORPORATE_THEME} from './styles/theme.corporate';
import {DARK_THEME} from './styles/theme.dark';
import {MATERIAL_LIGHT_THEME} from './styles/material/theme.material-light';
import {MATERIAL_DARK_THEME} from './styles/material/theme.material-dark';
import {RouterModule} from '@angular/router';
import {NoMenuLayoutComponent} from './layouts/no-menu/no-menu.layout';
import {NotLoggedLayoutComponent} from './layouts/not-logged/not-logged.layout';
import {HeaderNotLoggedComponent} from './components/header-not-logged/header-not-logged.component';
import {TranslateModule} from '../@core/lang/translate.module';
import {LocalDateTimePipe} from '../@core/utils/date/date.pipe';
import {EditionSelectorComponent} from './components/edition-selector/edition-selector.component';
import {RomanNumeralsPipePipe} from './pipes/roman-numerals.pipe';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
];
const COMPONENTS = [
  HeaderNotLoggedComponent,
  HeaderComponent,
  FooterComponent,
  EditionSelectorComponent,
  SearchInputComponent,
  TinyMCEComponent,
  NotLoggedLayoutComponent,
  NoMenuLayoutComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  LocalDateTimePipe,
  RomanNumeralsPipePipe,
];

@NgModule({
  imports: [CommonModule, MatRippleModule, RouterModule, TranslateModule, ...NB_MODULES],
  exports: [CommonModule, MatRippleModule, TranslateModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME, MATERIAL_LIGHT_THEME, MATERIAL_DARK_THEME ],
        ).providers,
      ],
    };
  }
}
