import {Component, OnInit} from '@angular/core';
import {CurrentEditionService, EditionCrud, EditionStatus, ResourceWithEdition} from '../../../@core/data/edk/edition';
import {TranslateService} from '../../../@core/lang/translate.service';
import {CurrentResourceService} from '../../../@core/data/edk/current-resource';
import {filter, map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
    selector: 'ngx-edition-selector',
    templateUrl: 'edition-selector.component.html',
    styleUrls: ['edition-selector.component.scss'],
})
export class EditionSelectorComponent implements OnInit {
    constructor(private currentEditionService: CurrentEditionService,
                private editionCrud: EditionCrud,
                private currentResourceService: CurrentResourceService,
                private msg: TranslateService,
                private router: Router) {
    }
    protected readonly ACTIVE = EditionStatus.ACTIVE;

    showSelector: boolean = false;
    editions: ResourceWithEdition[] = [];
    selectedEdition: ResourceWithEdition;
    private currentResourceId: string = '';

    readonly DISABLED_EDITION_SWITCH_FOR_URIS = ['/edit', '/status', '/create'];

    enableSelector() {
        const currentUrl = this.router.url;
        if (this.DISABLED_EDITION_SWITCH_FOR_URIS.filter(suffix => currentUrl.endsWith(suffix)).length > 0) {
            return;
        }
        if (!this.editions || this.editions.length <= 1) {
            return;
        }

        this.showSelector = true;
    }

    switchEdition(editionId: string) {
        this.selectedEdition = this.editions.filter(re => re.edition.id === editionId)[0];
        this.currentEditionService.setEdition(this.selectedEdition.edition);

        this.showSelector = this.selectedEdition.edition.status === EditionStatus.CLOSED;

        const currentUrl = this.router.url;
        if (currentUrl.includes(this.currentResourceId) && this.selectedEdition.resourceId) {
            const targetUrl = currentUrl.replace(this.currentResourceId, this.selectedEdition.resourceId);
            this.router.navigate([targetUrl]);
        }
    }

    label(re: ResourceWithEdition) {
        let label = re.edition.name;
        if (re.edition.status !== EditionStatus.ACTIVE) {
            label = label + ' - ' + this.msg.tr('editionStatus_' + re.edition.status);
        }
        return label;
    }

    ngOnInit(): void {
        const currentResource$ = this.currentResourceService.getCurrentResource()
            .pipe(map(({currentResource}) => currentResource));

        currentResource$.subscribe(currentResource => {
            this.currentResourceId = currentResource.id;

            this.editionCrud.findHistory(currentResource.type, currentResource.id).subscribe(history => {
                this.currentEditionService.currentEdition()
                    .pipe(filter(currentEdition => currentEdition && true))
                    .subscribe(currentEdition => {
                        this.selectedEdition = history.filter(re => re && re.edition && re.edition.id === currentEdition.id)[0];
                        this.showSelector = this.selectedEdition.edition.status === EditionStatus.CLOSED;

                        this.editions = history;
                    })
            });
        })

    }
}