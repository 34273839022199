import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Injectable} from '@angular/core';
import {CurrentResourceService} from '../data/edk/current-resource';
import {map} from 'rxjs/operators';
import {TranslateService} from '../lang/translate.service';

@Injectable({
    providedIn: 'root'
})
export class EdkTitleStrategy extends TitleStrategy {
    constructor(private title: Title,
                private tr: TranslateService,
                private currentResourceService: CurrentResourceService) {
        super();
    }

    override updateTitle(snapshot: RouterStateSnapshot) {
        const routingTitle = this.buildTitle(snapshot);
        this.currentResourceService.getCurrentResource()
            .pipe(map(({currentResource}) => currentResource))
            .subscribe(currentResource => {
                let title = 'e-DK Panel'
                if (currentResource.name) {
                    title = 'e-DK - ' + currentResource.name;
                } else if (routingTitle) {
                    title = 'e-DK - ' + this.tr.tr(routingTitle);
                }
                this.title.setTitle(title);
            });
    }
}