import {Observable} from 'rxjs';

export namespace AreaRequestStatus {
    export const NEW = 'NEW';
    export const APPROVED = 'APPROVED';
    export const REJECTED = 'REJECTED';
}

export interface AreaRequest {
    id?: string;
    editionId: string;
    areaGroupId?: string;
    areaGroupName?: string;
    areaId?: string;
    territoryId?: string;
    territoryName?: string;
    country: string;
    name: string;
    createdAt?: string;
    creatorEmail: string;
    creatorTitle: string;
    creatorFirstName: string;
    creatorLastName: string;
    creatorPhone: string;
    creatorLang: string;
    creatorParticipant: boolean;
    creatorLeader: boolean;
    creatorExperience: string;
    creationReason: string;
    understanding: string;
    routeFrom: string;
    routeTo: string;
    routeLength: number;
    routeAscent: number;
    participants: number;
    additionalInfo?: string;
    dkType: string;
    status?: string;
    rejectionReason?: string;
}

export interface AreaRequestPage {
    data: AreaRequest[];
    total: number;
    page: number;
    pageSize: number;
}
export interface AssignAreaRequestToGroupCommand {
    areaRequestId: string;
    areaGroupId: string;
}
export interface UpdateAreaRequestStatusCommand {
    areaRequestId: string;
    status: string;
    comment: string;
}
export abstract class AreaRequestCrud {
    abstract create(req: AreaRequest): Observable<AreaRequest>;

    abstract findRequest(id: string): Observable<AreaRequest>;

    abstract findRequests(editionId: string, areaGroupId: string, statuses: string[],
                          page: number, pageSize: number): Observable<AreaRequestPage>;
    abstract updateAreaGroup(command: AssignAreaRequestToGroupCommand): Observable<any>;
    abstract updateStatus(command: UpdateAreaRequestStatusCommand): Observable<any>;
}
