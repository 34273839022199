<span class="created-by">
      Copyright &#169; 2024 Fundacja Indywidualności Otwartych
</span>

<div>
    <nb-actions size="small">
        <nb-action class="control-item">
            <nb-select size="small"
                       matRipple
                       [selected]="currentLang"
                       (selectedChange)="changeLang($event)">
                <nb-option
                        *ngFor="let lang of languages"
                        [value]="lang"
                        matRipple
                >{{ lang }}</nb-option>
            </nb-select>
        </nb-action>
        <nb-action class="control-item">
            <nb-select size="small"
                       matRipple
                       [selected]="currentTheme"
                       (selectedChange)="changeTheme($event)">
                <nb-option
                        *ngFor="let theme of themes"
                        [value]="theme.value"
                        matRipple
                >{{ theme.name }}</nb-option>
            </nb-select>
        </nb-action>
    </nb-actions>
</div>


