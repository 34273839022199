import {LeadersForEditions, LeadersProvider} from '../data/edk/user';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable()
export class LeadersProviderImpl extends LeadersProvider {
    constructor(private http: HttpClient) {
        super();
    }

    findLeaders(editionId: string): Observable<LeadersForEditions> {
        return this.http.get<LeadersForEditions>(API_URL + '/api/v1/user-access/leaders', {params: {editionId: editionId}});
    }

}