import {Observable} from 'rxjs';

export namespace AreaStatus {
    export const DRAFT = 'DRAFT';
    export const FOR_ACTIVATION = 'FOR_ACTIVATION';
    export const ACTIVE = 'ACTIVE';
    export const INACTIVE = 'INACTIVE';
    export const REMOVED = 'REMOVED';
}

export namespace AreaNoteType {
    export const BEGINNING = 'N10_BEGINNING';
    export const REGISTER = 'N20_REGISTER';
    export const ADDITIONAL = 'N30_ADDITIONAL';
}

export interface AreaNote {
    id: string;
    areaId?: string;
    content: string;
    type: string;
}

export interface Area {
    id: string;
    name: string;
    slug?: string;
    eventDate?: string;
    eventTime?: string;
    prevAreaId?: string;
    editionId: string;
    areaGroupId?: string;
    territoryId?: string;
    country?: string;
    areaWebsite?: string;
    facebookProfile?: string;
    responsiblePriest?: string;
    parishName?: string;
    parishWebsite?: string;
    parishAddress?: string;
    parishPostal?: string;
    parishCity: string;
    latitude?: number;
    longitude?: number;
    dkType: string;
    status: string;
    notes?: AreaNote[];
    addons?: any;
}

export interface AreaPage {
    data: Area[];
    total: number;
    page: number;
    pageSize: number;
}

export interface AssignAreaToGroupCommand {
    areaId: string;
    areaGroupId: string;
}

export interface UpdateAreaStatusCommand {
    areaId: string;
    status: string;
    comment: string;
}

export interface UpdateAreaLatLngCommand {
    areaId: string;
    latitude: number;
    longitude: number;
}

export interface AreaSnapshotUser {
    userName: string;
    accesses: string[];
}
export interface AreaSnapshotRoute {
    routeId: string;
    name: string;
    startPlace: string;
    endPlace: string;
    status: string;
}
export interface AreaSnapshot {
    name: string;
    areaGroupId: string;
    areaGroupName: string;
    territoryName: string;
    eventDate: string;
    eventTime: string;
    areaWebsite: string;
    facebookProfile: string;
    responsiblePriest: string;
    parishName: string;
    parishWebsite: string;
    parishAddress: string;
    parishPostal: string;
    parishCity: string;
    country: string;
    latitude: number;
    longitude: number;
    status: string;
    notes: {type: string, content: string}[];
    users: AreaSnapshotUser[];
    routes: AreaSnapshotRoute[];
}
export interface AreaLog {
    userName: string;
    eventTime: string;
    event: string;
    notes: string;
    snapshot: AreaSnapshot;
}

export abstract class AreaCrud {
    abstract create(area: Area): Observable<Area>;
    abstract update(area: Area): Observable<Area>;
    abstract updateAreaGroup(command: AssignAreaToGroupCommand): Observable<any>;
    abstract findAvailableStatuses(id: string): Observable<string[]>;
    abstract updateStatus(command: UpdateAreaStatusCommand): Observable<any>;
    abstract updateLatLng(command: UpdateAreaLatLngCommand): Observable<any>;
    abstract findArea(id: string): Observable<Area>;
    abstract findAreas(editionId: string, areaGroupId: string, withoutAreaGroup: boolean,
                       page: number, pageSize: number, q: string, dkType: string, statuses: string[]): Observable<AreaPage>;
    abstract findLogs(id: string): Observable<AreaLog[]>;
}
