import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {LoggedUserUpdater, User} from '../data/edk/user';

const API_URL = environment.apiUrl;

@Injectable()
export class LoggedUserUpdaterService extends LoggedUserUpdater {
    constructor(private http: HttpClient) {
        super();
    }

    getLoggedUser(): Observable<User> {
        return this.http.get<User>(API_URL + '/api/v1/logged-user');
    }

    update(user: User): Observable<User> {
        return this.http.put<User>(API_URL + '/api/v1/logged-user', user);
    }

    updatePassword(): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/logged-user/update-password', '');
    }
}
