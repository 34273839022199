import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
    CreateUserForResourceCommand,
    TeamMember,
    UpdateUserAccessCommand,
    UpdateUserAccessCommentCommand,
    User,
    UserAccesses,
    UserAccessesCrud,
    UserName,
    UsersPage
} from '../data/edk/user-access';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable()
export class UserAccessesCrudService extends UserAccessesCrud {
    constructor(private http: HttpClient) {
        super();
    }

    findUserAccesses(userId: string, resourceType: string, resourceId: string = ''): Observable<UserAccesses> {
        return this.http.get<UserAccesses>(API_URL + '/api/v1/user-access/user/' + userId + '/' + resourceType,
            {params: {resourceId: resourceId}});
    }

    findUsers(page: number, pageSize: number, q: string, resourceType: string = '', resourceId: string = '')
        : Observable<UsersPage> {
        return this.http.get<UsersPage>(API_URL + '/api/v1/user-access/users',
            {
                params: {
                    page: page,
                    pageSize: pageSize,
                    q: q,
                    resourceType: resourceType,
                    resourceId: resourceId,
                }
            });
    }

    findUserNames(resourceType: string, resourceId: string, q: string): Observable<UserName[]> {
        return this.http.get<UserName[]>(API_URL + '/api/v1/user-access/users-names/' + resourceType,
            {
                params: {
                    resourceId: resourceId,
                    q: q,
                }
            });
    }

    private updateInProgress: boolean = false;

    setUpdatingAccessInProgress(inProgress: boolean) {
        this.updateInProgress = inProgress;
    }

    getUpdatingAccessInProgress(): boolean {
        return this.updateInProgress;
    }

    updateUserAccess(command: UpdateUserAccessCommand) {
        return this.http.put<any>(API_URL + '/api/v1/user-access', command);
    }

    createUserForResource(command: CreateUserForResourceCommand): Observable<User> {
        return this.http.post<any>(API_URL + '/api/v1/user-access/create-user', command);
    }

    findTeamMembers(resourceType: string, resourceId: string): Observable<TeamMember[]> {
        return this.http.get<TeamMember[]>(API_URL + '/api/v1/user-access/team',
            {
                params: {
                    resourceType: resourceType,
                    resourceId: resourceId,
                }
            });
    }

    updateUserAccessComment(command: UpdateUserAccessCommentCommand): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/user-access/comment', command);
    }



}
