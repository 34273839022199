import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {LayoutService} from './utils';
import {RippleService} from './utils/ripple.service';
import {LeadersProvider, LoggedUserUpdater, UserCrud} from './data/edk/user';
import {UserCrudService} from './service/user-crud.service';
import {AreaGroupCrudService} from './service/area-group-crud.service';
import {AreaGroupCrud} from './data/edk/area-group';
import {LoggedUserUpdaterService} from './service/logged-user-updater.service';
import {UserAccessesCrud} from './data/edk/user-access';
import {UserAccessesCrudService} from './service/user-access-crud.service';
import {CurrentResourceServiceImpl} from './service/current-resource-service-impl.service';
import {CurrentResourceService} from './data/edk/current-resource';
import {AreaCrudService} from './service/area-crud.service';
import {AreaCrud} from './data/edk/area';
import {CurrentEditionService, EditionCrud} from './data/edk/edition';
import {EditionCrudService} from './service/edition-crud.service';
import {CurrentEditionServiceImpl} from './service/current-edition.service';
import {EdkRouteCrud} from './data/edk/route';
import {EdkRouteCrudService} from './service/route-crud.service';
import {TerritoryCrudService} from './service/territory-crud.service';
import {TerritoryCrud} from './data/edk/territory';
import {ContractCrudService} from './service/contract-crud.service';
import {ContractCrud} from './data/edk/contract';
import {LoggedUserContractServiceImpl} from './service/logged-user-contract.service';
import {LoggedUserContractService} from './data/edk/user-contract';
import {AreaRequestCrudService} from './service/area-request-crud.service';
import {AreaRequestCrud} from './data/edk/area-request';
import {RouteFileProviderService} from './service/route-file-provider.service';
import {RouteFileProvider, RouteFileUploader} from './data/edk/file';
import {RouteFileUploaderService} from './service/route-file-uploader.service';
import {ParticipantsReportProvider} from './data/edk/participant';
import {ParticipantsReportProviderService} from './service/participants-report-provider.service';
import {LatestActivityProviderImpl} from './service/latest-activity-provider.service';
import {LatestActivityProvider} from './data/edk/activity';
import {LeadersProviderImpl} from './service/leaders-provider.service';
import {LearningResourceServiceImpl} from './service/learning-resource.service';
import {LearningResourceService} from './data/edk/learning-resource';
import {DashboardInfoProvider} from './data/edk/dashboard';
import {DashboardInfoProviderImpl} from './service/dashboard-info-provider.service';
import {TextSanitizerService} from './service/text-sanitizer.service';
import {RouteGuideCrudService} from './service/route-guide-crud.service';
import {RouteGuideCrud} from './data/edk/route-guide';

const DATA_SERVICES = [
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useExisting: RippleService},

    // EDK:
    {provide: CurrentResourceService, useClass: CurrentResourceServiceImpl},
    {provide: UserCrud, useClass: UserCrudService},
    {provide: UserAccessesCrud, useClass: UserAccessesCrudService},
    {provide: LoggedUserUpdater, useClass: LoggedUserUpdaterService},
    {provide: EditionCrud, useClass: EditionCrudService},
    {provide: CurrentEditionService, useClass: CurrentEditionServiceImpl},
    {provide: AreaGroupCrud, useClass: AreaGroupCrudService},
    {provide: AreaCrud, useClass: AreaCrudService},
    {provide: AreaRequestCrud, useClass: AreaRequestCrudService},
    {provide: EdkRouteCrud, useClass: EdkRouteCrudService},
    {provide: RouteGuideCrud, useClass: RouteGuideCrudService},
    {provide: RouteFileProvider, useClass: RouteFileProviderService},
    {provide: RouteFileUploader, useClass: RouteFileUploaderService},
    {provide: TerritoryCrud, useClass: TerritoryCrudService},
    {provide: ContractCrud, useClass: ContractCrudService},
    {provide: LoggedUserContractService, useClass: LoggedUserContractServiceImpl},
    {provide: ParticipantsReportProvider, useClass: ParticipantsReportProviderService},
    {provide: LatestActivityProvider, useClass: LatestActivityProviderImpl},
    {provide: LeadersProvider, useClass: LeadersProviderImpl},
    {provide: LearningResourceService, useClass: LearningResourceServiceImpl},
    {provide: DashboardInfoProvider, useClass: DashboardInfoProviderImpl},
    {provide: TextSanitizerService, useClass: TextSanitizerService},
];

export const NB_CORE_PROVIDERS = [
    ...DATA_SERVICES,
    LayoutService,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [],
    declarations: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}
