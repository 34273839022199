import {NgModule} from '@angular/core';
import {TranslatePipe} from './translate.pipe';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {EdkMatPaginatorIntl} from './paginator/edk-mat-paginator-intl';
import {TranslateService} from './translate.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';

export const EDK_DATE_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD'
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

@NgModule({
    declarations: [
        TranslatePipe,
    ],
    exports: [
        TranslatePipe,
    ],
    providers: [
        TranslateService,
        {provide: MatPaginatorIntl, useClass: EdkMatPaginatorIntl},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: EDK_DATE_FORMATS},
    ],
})
export class TranslateModule {
}
