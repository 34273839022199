import {Observable} from 'rxjs';

export interface AreaGroup {
    id: string;
    prevAreaGroupId?: string;
    editionId: string;
    name: string;
    description: string;
    addons?: any;
}

export interface AreaGroupPage {
    data: AreaGroup[];
    total: number;
    page: number;
    pageSize: number;
}

export abstract class AreaGroupCrud {
    abstract create(areaGroup: AreaGroup): Observable<AreaGroup>;
    abstract update(areaGroup: AreaGroup): Observable<AreaGroup>;
    abstract findAreaGroup(id: string): Observable<AreaGroup>;
    abstract findAreaGroups(editionId: string, page: number, pageSize: number, q: string): Observable<AreaGroupPage>;
    abstract findAreaGroupsAsOptions(editionId: string): Observable<any[]>;
}
