import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';


@Pipe({
    name: 'localDateTime',
})
export class LocalDateTimePipe implements PipeTransform {
    constructor() {
    }

    transform(value: any, ...params: any[]): any {
        if (!value) {
            return '';
        }
        return moment(value).format('YYYY-MM-DD HH:mm')
    }
}