import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from './translate.service';

@Pipe({
    name: 'msg',
})
export class TranslatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    transform(key: any, ...params: any[]): any {
        return this.translateService.tr(key, ...params);
    }
}
