import {CurrentEditionService, Edition, EditionCrud} from '../data/edk/edition';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable()
export class CurrentEditionServiceImpl extends CurrentEditionService {
    constructor(private editionCrud: EditionCrud) {
        super();
    }

    private currentEditionSubject = new ReplaySubject<Edition>(1);
    private currentEdition$: Observable<Edition> = this.currentEditionSubject.asObservable();

    private viewedEdition: Edition;
    private editableEdition: Edition;

    init() {
        if (!this.editableEdition) {
            this.fetchEditableEdition();
        }
    }

    fetchEditableEdition() {
        this.editionCrud.findCurrentEdition()
            .subscribe(edition => {
                this.editableEdition = edition;
                this.viewedEdition = edition;
                this.currentEditionSubject.next(edition);
            });
    }

    setEdition(edition: Edition) {
        if (this.viewedEdition && this.viewedEdition.id === edition.id && this.viewedEdition.status === edition.status) {
            return;
        }
        this.viewedEdition = edition;
        this.currentEditionSubject.next(edition);
    }

    currentEdition(): Observable<Edition> {
        return this.currentEdition$.pipe(filter(edition => edition && true));
    }

}
