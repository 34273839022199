import {CurrentResource, CurrentResourceService} from '../data/edk/current-resource';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {TranslateService} from '../lang/translate.service';
import {ResourceType} from '../data/edk/resource-type';
import {WayOfCrossType} from '../data/edk/way-of-cross-type';

@Injectable()
export class CurrentResourceServiceImpl extends CurrentResourceService {
    constructor(private tr: TranslateService) {
        super();
    }

    private currentResource: CurrentResource;
    private currentResourceChanges$: ReplaySubject<{ currentResource: CurrentResource, previous: CurrentResource }>
        = new ReplaySubject(1);

    setCurrentResource(obj: any, resourceType: string) {
        let name = obj ? obj.name : '';
        if ((resourceType === ResourceType.AREA || resourceType === ResourceType.ROUTE)
            && obj
            && obj.dkType !== WayOfCrossType.STANDARD) {

            name = name + ' (' + this.tr.tr('wayOfCrossTypeShort_' + obj.dkType) + ')';
        } else if (resourceType === ResourceType.AREA_GROUP) {
            name = obj.description;
        }

        const res: CurrentResource = {id: obj.id, name: name, type: resourceType, obj: obj};
        this.currentResourceChanges$.next({currentResource: res, previous: this.currentResource});
        this.currentResource = res;
    }

    getCurrentResource(resourceType?: string): Observable<any> {
        return this.currentResourceChanges$.pipe(
            filter(res => !resourceType || res.currentResource?.type === resourceType));
    }
}
