import {ActivityLog, LatestActivityProvider} from '../data/edk/activity';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable()
export class LatestActivityProviderImpl extends LatestActivityProvider {
    constructor(private http: HttpClient) {
        super();
    }

    findActivities(editionId: string, areaGroupId: string): Observable<ActivityLog[]> {
        return this.http.get<ActivityLog[]>(API_URL + '/api/v1/latest-activity', {
            params: {
                editionId: editionId,
                areaGroupId: areaGroupId,
            }});
    }

}
