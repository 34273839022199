import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';

export namespace LearningResourceType {
    export const LR_10_STANDARDS = 'LR_10_STANDARDS';
    export const LR_15_FAQ = 'LR_15_FAQ';
    export const LR_20_ROUTES = 'LR_20_ROUTES';
    export const LR_30_AREA = 'LR_30_AREA';
    export const LR_40_PROMOTION = 'LR_40_PROMOTION';
    export const LR_50_ADDITIONAL = 'LR_50_ADDITIONAL';
    export const LR_60_GADGETS = 'LR_60_GADGETS';
    export const LR_70_PANEL = 'LR_70_PANEL';
}

export interface LearningResource {
    id?: string;
    name: string;
    description: string;
    fileSize?: string;
    contentType?: string;
    externalUrl?: string;
    content?: string;
    type: string;
    resourceTypes: string[];
    showOrder: number;
    editionId: string;
}
export abstract class LearningResourceService {
    abstract save(learningResource: LearningResource, file: File): Observable<HttpEvent<any>>;
    abstract find(editionId: string, resourceType: string): Observable<any>;
    abstract delete(learningResourceId: string): Observable<any>;
}