<div class="header-container">
    <div class="logo-container">
        <a *ngIf="sideMenuVisible"
           href="#"
           class="sidebar-toggle"
           matRipple
           [matRippleUnbounded]="true"
           [matRippleCentered]="true"
           (click)="toggleSidebar()"
        >
            <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
        </a>
        <a class="logo" routerLink="/edk-pages/dashboard"><img src="assets/img/logo.png" alt="e-DK Panel" class="logo-symbol"></a>
    </div>

    <div class="pr-4 font-weight-bolder current-resource" *ngIf="currentResource && currentResource.id">
        <nb-icon *ngIf="currentResource.type === 'ROUTE'" icon="trending-up-outline"></nb-icon>
        <nb-icon *ngIf="currentResource.type === 'AREA'" icon="globe-outline"></nb-icon>
        <nb-icon *ngIf="currentResource.type === 'AREA_GROUP'" icon="pie-chart-outline"></nb-icon>
        {{'resourceType_' + currentResource.type | msg}}: {{currentResource.name}}</div>

    <div class="pr-2" *ngIf="currentEdition && currentEdition.id">
        <ngx-edition-selector></ngx-edition-selector>
    </div>

</div>

<div class="header-container">
    <nb-actions size="small">

        <nb-action class="control-item" *ngIf="areaGroupsVisible">
            <a class="text-control top-menu-item" routerLink="/edk-pages/area-groups" routerLinkActive="top-menu-active">{{'areaGroups' | msg}}</a>
        </nb-action>
        <nb-action class="control-item" *ngIf="areasVisible">
            <a class="text-control top-menu-item" routerLink="/edk-pages/areas" routerLinkActive="top-menu-active">{{'areas' | msg}}</a>
        </nb-action>
        <nb-action class="control-item" *ngIf="routesVisible">
            <a class="text-control top-menu-item" routerLink="/edk-pages/routes" routerLinkActive="top-menu-active">{{'routes' | msg}}</a>
        </nb-action>
        <nb-action class="control-item" *ngIf="adminVisible">
            <a class="text-control top-menu-item" routerLink="/edk-pages/admin" routerLinkActive="top-menu-active">{{'admin' | msg}}</a>
        </nb-action>

        <nb-action *ngIf="isAuthenticated && (userDetails$ | async) as userDetails"
                   class="user-action"
                   matRipple
                   [matRippleUnbounded]="true"
                   [matRippleCentered]="true">
            <nb-user [nbContextMenu]="userMenu"
                     [nbContextMenuTag]="'logged-user'"
                     [onlyPicture]="userPictureOnly"
                     [name]="userDetails?.name">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
