import {RouteFileUploader} from '../data/edk/file';
import {Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

const API_URL = environment.apiUrl;

@Injectable()
export class RouteFileUploaderService extends RouteFileUploader {
    constructor(private http: HttpClient) {
        super();
    }

    upload(routeId: string, fileType: string, lang: string, file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);
        const req = new HttpRequest('POST', API_URL + '/api/v1/route-file/upload/'
            + routeId + '/' + fileType + '/' + lang, formData, {
            reportProgress: true,
            responseType: 'json',
        });

        return this.http.request(req);
    }
}
