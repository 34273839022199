import {LoggedUserContractService, UserContract} from '../data/edk/user-contract';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable()
export class LoggedUserContractServiceImpl extends LoggedUserContractService {
    constructor(private http: HttpClient) {
        super();
    }

    findContractsForLoggedUser(): Observable<UserContract[]> {
        return this.http.get<UserContract[]>(API_URL + '/api/v1/user-contract/logged');
    }

    findContractsToBeSignedByLoggedUser(resourceType: string, resourceId: string, editionId: string)
        : Observable<UserContract[]> {
        return this.http.get<UserContract[]>(API_URL + '/api/v1/user-contract/logged/to-be-signed', {params: {
                resourceType: resourceType,
                resourceId: resourceId,
                editionId: editionId,
            }});
    }

    signContract(userContract: UserContract): Observable<any> {
        const command = {userContractId: userContract.id};
        return this.http.put<any>(API_URL + '/api/v1/user-contract/logged/sign', command);
    }
}
