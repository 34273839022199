import {Territory, TerritoryCrud} from '../data/edk/territory';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable()
export class TerritoryCrudService extends TerritoryCrud {
    constructor(private http: HttpClient) {
        super();
    }

    findTerritories(editionId: string): Observable<Territory[]> {
        return this.http.get<Territory[]>(API_URL + '/api/v1/territory', {
            params: {
                editionId: editionId,
            }});
    }

    findTerritory(id: string): Observable<Territory> {
        return this.http.get<Territory>(API_URL + '/api/v1/territory/' + id);
    }

}

