import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AllUsersPage, User, UserCrud, UserDetails} from '../data/edk/user';
import {Observable} from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable()
export class UserCrudService extends UserCrud {
    constructor(private http: HttpClient) {
        super();
    }

    loggedUserDetails(): Observable<UserDetails> {
        return this.http.get<UserDetails>(API_URL + '/api/v1/user/logged');
    }

    findAllUsers(page: number, pageSize: number, q: string): Observable<AllUsersPage> {
        return this.http.get<AllUsersPage>(API_URL + '/api/v1/users/find',
            {
                params: {
                    page: page,
                    pageSize: pageSize,
                    q: q
                }});
    }

    findUserWithResources(userId: string, editionId: string): Observable<User> {
        return this.http.get<User>(API_URL + '/api/v1/user/with-resources',{
            params: {
                userId: userId,
                editionId: editionId
            }});
    }

    deleteUser(userId: string): Observable<any> {
        return this.http.delete(API_URL + '/api/v1/user/delete/' + userId);
    }

}
