import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable()
export class TextSanitizerService {
    constructor(private http: HttpClient) {
    }

    sanitize(text: string, mode: string = 'html'): Observable<string> {
        return this.http.post<{data: string}>(API_URL + '/api/v1/sanitize/' + mode, {data: text})
            .pipe(map(result => result.data));
    }
}
