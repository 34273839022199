import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';

const KEYCLOAK_URL = environment.keycloakUrl;


export const AuthCodeFlowConfig: AuthConfig = {
    issuer: `${KEYCLOAK_URL}/realms/EDK`,
    redirectUri: window.location.origin + '/edk-pages/dashboard',
    clientId: 'edk-frontend',
    responseType: 'code',
    scope: 'openid profile email offline_access',
    disableAtHashCheck: true,
    showDebugInformation: true,
    timeoutFactor: 0.75,
    postLogoutRedirectUri: window.location.origin + '/edk-pages/home',
    logoutUrl: `${KEYCLOAK_URL}/realms/EDK/protocol/openid-connect/logout`,
};
