import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';

export namespace FileType {
    export const ROUTE_TRACK = 'ROUTE_TRACK';
    export const ROUTE_DESCRIPTION = 'ROUTE_DESCRIPTION';
    export const ROUTE_MAP = 'ROUTE_MAP';
}

export interface FileInfo {
    id: string;
    name: string;
    lang: string;
    type: string;
    link: string;
}

export abstract class RouteFileProvider {
    abstract fileInfos(routeId: string, type?: string): Observable<FileInfo[]>;
}

export abstract class RouteFileUploader {
    abstract upload(routeId: string, fileType: string, lang: string, file: File): Observable<HttpEvent<any>>;
}
