import {Observable} from 'rxjs';

export interface CurrentResource {
    id: string;
    name: string;
    type: string;
    obj: any;
}

export abstract class CurrentResourceService {
    abstract setCurrentResource(obj: any, resourceType: string);
    abstract getCurrentResource(resourceType?: string): Observable<any>;
}
