import {Injectable} from '@angular/core';

import * as pl from './messages/messages-pl.json';
import * as en from './messages/messages-en.json';
import {BehaviorSubject} from 'rxjs';
import {DateAdapter} from '@angular/material/core';

@Injectable()
export class TranslateService {
    constructor(private dateAdapter: DateAdapter<any>) {
        const lang = localStorage.getItem(this.LS_LANG_KEY) ? localStorage.getItem(this.LS_LANG_KEY) : this.defaultLang;
        this.setLang(lang);

        this.dateAdapter.setLocale(lang);
    }
    readonly defaultLang = 'pl';

    readonly LS_LANG_KEY = 'edkPanelLang'

    private langMessages = {pl: pl, en: en};
    private lang: string;

    private langSubj = new BehaviorSubject<string>(this.defaultLang);
    lang$ = this.langSubj.asObservable();

    setLang(lang: string) {
        localStorage.setItem(this.LS_LANG_KEY, lang);
        this.lang = lang;
        this.langSubj.next(lang);
    }

    getLang() {
        return this.lang;
    }

    tr(key: string, params: any[] = []): string {
        let translated = this.langMessages[this.lang][key];
        if (!translated && this.lang !== this.defaultLang) {
            translated = this.langMessages[this.defaultLang][key];
        }
        if (!translated) {
            translated = key;
        }

        if (params && params.length) {
            for (let i = 0; i < params.length; i++) {
                translated = translated.replace('{' + i + '}', params[i]);
            }
        }

        return translated;
    }
}
