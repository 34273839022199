import {Observable} from 'rxjs';

export interface Contract {
    id: string;
    editionId: string;
    content: string;
    name: string;
    targetUsers: string;
    status: string;
    mandatory: boolean;
}

export abstract class ContractCrud {
    abstract findContract(contractId: string): Observable<Contract>;
    abstract findContractsByEdition(editionId: string): Observable<Contract[]>;
    abstract create(contract: Contract): Observable<Contract>;
    abstract update(contract: Contract): Observable<Contract>;
    abstract updateContractStatus(contractId: string, status: string): Observable<Contract>;
    abstract removeContract(contractId: string): Observable<any>;
}
