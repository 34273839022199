import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthCodeFlowConfig} from './auth.config';
import {Observable, ReplaySubject} from 'rxjs';
import {UserCrud, UserDetails} from '../@core/data/edk/user';
import {CurrentEditionService} from '../@core/data/edk/edition';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private router: Router,
                private oauthService: OAuthService,
                private userCrud: UserCrud,
                private currentEditionService: CurrentEditionService) {
        this.oauthService.configure(AuthCodeFlowConfig);
    }

    public authenticationEventObservable: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private authenticated: boolean = false;

    private loggedUserSubject: ReplaySubject<UserDetails> = new ReplaySubject<UserDetails>(1);
    public loggedUser$: Observable<UserDetails> = this.loggedUserSubject.asObservable();

    public logout() {
        if (!this.authenticated) {
            return;
        }
        this.authenticated = false;

        this.oauthService.revokeTokenAndLogout()
            .then(() => {
                this.loggedUserSubject.next(null);
            })
            .catch(() => {});
    }

    public login() {
        this.oauthService
            .loadDiscoveryDocumentAndLogin()
            .then((result: boolean) => this.logged(result))
            .catch(() => {
                this.router.navigate(['/edk-pages/home']);
            });

        this.oauthService.setupAutomaticSilentRefresh();
    }

    private readonly AUTO_LOGIN = 'AUTO_LOGIN';
    public autoLogin() {
        const autoLoginFlag = localStorage.getItem(this.AUTO_LOGIN);
        if (autoLoginFlag && autoLoginFlag === 'true') {
            localStorage.setItem(this.AUTO_LOGIN, undefined);
            this.login();
        }
    }
    public setAutoLogin() {
        localStorage.setItem(this.AUTO_LOGIN, 'true');
    }

    public reloadLoggedUserDetails() {
        this.userCrud.loggedUserDetails()
            .subscribe(userDetails => this.loggedUserSubject.next(userDetails));
    }

    private logged(logged: boolean) {
        if (logged && !this.authenticated) {
            this.authenticated = true;
            this.authenticationEventObservable.next(logged);
            this.userCrud.loggedUserDetails().subscribe(userDetails => this.loggedUserSubject.next(userDetails));
            this.currentEditionService.init();
        }
    }
}
