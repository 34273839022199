import {User} from './user';
import {Observable} from 'rxjs';

export interface UserContract {
    id: string;
    user: User;
    content: string;
    name: string;
    mandatory: boolean;
    resourceId: string;
    resourceName: string;
    editionId: string;
    editionName: string;
    signed: boolean;
    signedAt: string;
    cancelled: boolean;
    cancelledAt: string;
}

export abstract class LoggedUserContractService {
    abstract findContractsForLoggedUser(): Observable<UserContract[]>;
    abstract findContractsToBeSignedByLoggedUser(resourceType: string, resourceId: string, editionId: string)
        : Observable<UserContract[]>;
    abstract signContract(userContract: UserContract): Observable<UserContract>;
}
