import {Area, AreaCrud, AreaLog, AreaPage, AssignAreaToGroupCommand, UpdateAreaLatLngCommand, UpdateAreaStatusCommand} from '../data/edk/area';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable()
export class AreaCrudService extends AreaCrud {
    constructor(private http: HttpClient) {
        super();
    }

    create(area: Area): Observable<Area> {
        return this.http.post<Area>(API_URL + '/api/v1/area', area);
    }

    update(area: Area): Observable<Area> {
        return this.http.put<Area>(API_URL + '/api/v1/area', area);
    }

    updateAreaGroup(command: AssignAreaToGroupCommand): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/area/area-group', command);
    }
    findAvailableStatuses(id: string): Observable<string[]> {
        return this.http.get<string[]>(API_URL + '/api/v1/area/' + id + '/available-statuses');
    }
    updateStatus(command: UpdateAreaStatusCommand): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/area/status', command);
    }

    updateLatLng(command: UpdateAreaLatLngCommand): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/area/area-lat-lng', command);
    }

    findArea(id: string): Observable<Area> {
        return this.http.get<Area>(API_URL + '/api/v1/area/' + id);
    }

    findAreas(editionId: string, areaGroupId: string, withoutAreaGroup: boolean, page: number, pageSize: number, q: string, dkType: string, statuses: string[]): Observable<AreaPage> {
        return this.http.get<AreaPage>(API_URL + '/api/v1/area', {
            params: {
                page: page,
                pageSize: pageSize,
                editionId: editionId,
                areaGroupId: areaGroupId,
                withoutAreaGroup: withoutAreaGroup,
                statuses: statuses,
                q: q,
                dkType: dkType,
            }});
    }

    findLogs(id: string): Observable<AreaLog[]> {
        return this.http.get<AreaLog[]>(API_URL + '/api/v1/area/' + id + '/logs');
    }

}
