import {Contract, ContractCrud} from '../data/edk/contract';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable()
export class ContractCrudService extends ContractCrud {
    constructor(private http: HttpClient) {
        super();
    }

    findContract(contractId: string): Observable<Contract> {
        return this.http.get<Contract>(API_URL + '/api/v1/contract/' + contractId);
    }

    findContractsByEdition(editionId: string): Observable<Contract[]> {
        return this.http.get<Contract[]>(API_URL + '/api/v1/contract', {params: {editionId: editionId}});
    }

    create(contract: Contract): Observable<Contract> {
        return this.http.post<Contract>(API_URL + '/api/v1/contract', contract);
    }

    update(contract: Contract): Observable<Contract> {
        return this.http.put<Contract>(API_URL + '/api/v1/contract', contract);
    }

    updateContractStatus(contractId: string, status: string): Observable<Contract> {
        const command = {contractId: contractId, status: status};
        return this.http.put<Contract>(API_URL + '/api/v1/contract/status', command);
    }

    removeContract(contractId: string): Observable<any> {
        return this.http.delete<any>(API_URL + '/api/v1/contract/' + contractId);
    }
}
