import {CreateEdkEditionCommand, Edition, EditionCrud, ResourceWithEdition, UpdateEdkEditionStatusCommand} from '../data/edk/edition';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

const API_URL = environment.apiUrl;

@Injectable()
export class EditionCrudService extends EditionCrud {
    constructor(private http: HttpClient) {
        super();
    }

    findCurrentEdition(): Observable<Edition> {
        return this.http.get<Edition>(API_URL + '/api/v1/edition/current');
    }

    findAll(): Observable<Edition[]> {
        return this.http.get<Edition[]>(API_URL + '/api/v1/edition');
    }

    findEdition(id: string): Observable<Edition> {
        return this.http.get<Edition>(API_URL + '/api/v1/edition/' + id);
    }

    create(command: CreateEdkEditionCommand): Observable<Edition> {
        return this.http.post<Edition>(API_URL + '/api/v1/edition', command);
    }

    checkEditionCreationFinished(): Observable<any> {
        return this.http.get<any>(API_URL + '/api/v1/edition/creation-finished');
    }

    updateEditionStatus(command: UpdateEdkEditionStatusCommand): Observable<any> {
        return this.http.put<Edition>(API_URL + '/api/v1/edition/update-status', command);
    }

    findHistory(resourceType: string, resourceId: string): Observable<ResourceWithEdition[]> {
        return this.http.get<ResourceWithEdition[]>(API_URL + '/api/v1/edition/history', {params: {
                resourceType: resourceType,
                resourceId: resourceId,
            }});
    }



}
