import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';

export namespace RouteStatus {
    export const DRAFT = 'DRAFT';
    export const FOR_APPROVAL = 'FOR_APPROVAL';
    export const REJECTED = 'REJECTED';
    export const APPROVED = 'APPROVED';
    export const REMOVED = 'REMOVED';
}
export namespace RouteNoteType {
    export const N01_GENERAL = 'N01_GENERAL';
    export const N02_FOR_WHOM = 'N02_FOR_WHOM';
    export const N03_RETURN = 'N03_RETURN';
    export const N04_SAFETY = 'N04_SAFETY';
}

export interface RouteNote {
    id: string;
    routeId?: string;
    content: string;
    type: string;
}

export interface EdkRoute {
    id: string;
    name: string;
    editionId: string;
    areaId: string;
    patron: string;
    color?: string;
    author: string;
    startPlace: string;
    startPlaceDetails: string;
    endPlace: string;
    endPlaceDetails: string;
    obstacles: string;
    course: string;
    length?: number;
    ascent?: number;
    ascentTotal?: number;
    dkType?: string;
    status?: string;
    statusComment?: string;
    notes?: RouteNote[];
    addons?: any;
}

export interface EdkRoutePage {
    data: EdkRoute[];
    total: number;
    page: number;
    pageSize: number;
}

export interface RouteVerificationItem {
    valid: boolean;
    value: any;
    unit: string;
}

export interface RouteVerificationStatus {
    pathLength: RouteVerificationItem;
    routeType: RouteVerificationItem;
    elevationGain: RouteVerificationItem;
    elevationLoss: RouteVerificationItem;
    elevationTotalChange: RouteVerificationItem;
    singlePath: RouteVerificationItem;
    numberOfStations: RouteVerificationItem;
    stationsOrder: RouteVerificationItem;
    stationsOnPath: RouteVerificationItem;
    logs: string[];
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface StationLatLng {
    lat: number;
    lng: number;
    index: number;
    distance: number;
    label: string;
}

export interface ElevationPoint {
    elevation: number;
    distance: number;
}

export interface RouteCharacteristics {
    pathStart: LatLng;
    pathEnd: LatLng;
    pathCoordinates: LatLng[];
    stations: StationLatLng[];
    elevationCharacteristics: ElevationPoint[];
}

export interface RouteWithPath {
    id: string;
    name: string;
    color: string;
    startPlace: string;
    endPlace: string;
    length: number;
    status: string;
    pathCoordinates: LatLng[];
}

export interface RouteCharacteristicsWithVerificationStatus {
    verificationStatus: RouteVerificationStatus;
    routeCharacteristics: RouteCharacteristics;
}

export interface UpdateRouteStatusCommand {
    routeId: string;
    status: string;
    comment: string;
}

export interface UpdateRouteColorCommand {
    routeId: string;
    color: string;
}

export abstract class EdkRouteCrud {
    abstract create(route: EdkRoute): Observable<EdkRoute>;

    abstract update(route: EdkRoute): Observable<EdkRoute>;

    abstract findAvailableStatuses(id: string): Observable<string[]>;

    abstract updateStatus(command: UpdateRouteStatusCommand): Observable<any>;
    abstract updateColor(command: UpdateRouteColorCommand): Observable<any>;

    abstract deleteRoute(id: string): Observable<any>;

    abstract findRoute(id: string): Observable<EdkRoute>;

    abstract findRoutes(editionId: string, areaGroupId: string, areaId: string, q: string, statuses: string[],
                        lengthMin: number, lengthMax: number, page: number, pageSize: number): Observable<EdkRoutePage>;
    abstract findRoutesColors(areaId: string): Observable<string[]>;

    abstract verifyKml(routeId: string, file: File): Observable<HttpEvent<RouteCharacteristicsWithVerificationStatus>>;

    abstract saveKml(routeId: string, file: File): Observable<HttpEvent<RouteCharacteristicsWithVerificationStatus>>;

    abstract findRouteCharacteristics(id: string): Observable<RouteCharacteristicsWithVerificationStatus>;
    abstract findRoutePathsByArea(areaId: string): Observable<RouteWithPath[]>;
}
