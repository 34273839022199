import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AreaRequest, AreaRequestCrud, AreaRequestPage,
    AssignAreaRequestToGroupCommand, UpdateAreaRequestStatusCommand} from '../data/edk/area-request';

const API_URL = environment.apiUrl;

@Injectable()
export class AreaRequestCrudService extends AreaRequestCrud {
    constructor(private http: HttpClient) {
        super();
    }

    create(req: AreaRequest): Observable<AreaRequest> {
        return this.http.post<AreaRequest>(API_URL + '/api/v1/area-request/create', req);
    }

    findRequest(id: string): Observable<AreaRequest> {
        return this.http.get<AreaRequest>(API_URL + '/api/v1/area-request/' + id);
    }

    findRequests(editionId: string, areaGroupId: string, statuses: string[], page: number, pageSize: number)
        : Observable<AreaRequestPage> {
        return this.http.get<AreaRequestPage>(API_URL + '/api/v1/area-request', {
            params: {
                page: page,
                pageSize: pageSize,
                editionId: editionId,
                areaGroupId: areaGroupId,
                statuses: statuses.join(','),
            }});
    }

    updateAreaGroup(command: AssignAreaRequestToGroupCommand): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/area-request/area-group', command);
    }

    updateStatus(command: UpdateAreaRequestStatusCommand): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/area-request/status', command);
    }
}
