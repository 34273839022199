import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../auth/auth.service';
import {CurrentEditionService, Edition} from '../../../@core/data/edk/edition';

@Component({
    selector: 'ngx-header-not-logged',
    styleUrls: ['header-not-logged.component.scss'],
    templateUrl: 'header-not-logged.component.html',
})
export class HeaderNotLoggedComponent implements OnInit {
    currentEdition: Edition;

    public constructor(private authService: AuthService,
                       private currentEditionService: CurrentEditionService) {
    }

    login() {
        this.authService.login();
    }

    ngOnInit() {
        this.currentEditionService.init();
        this.currentEditionService.currentEdition().subscribe(edition => this.currentEdition = edition);
    }

}
