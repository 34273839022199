import { Component } from '@angular/core';

@Component({
    selector: 'ngx-no-menu-layout',
    styleUrls: ['no-menu.layout.scss'],
    template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header [sideMenuVisible]="false"></ngx-header>
      </nb-layout-header>

      <nb-layout-column>
        <ng-content select="content"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class NoMenuLayoutComponent {}
