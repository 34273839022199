import {Observable} from 'rxjs';

export interface Participant {
    name: string;
    age: number;
    sex: string;
    title: string;
    newsletter: boolean;
    marketing: boolean;
    date: string;
    route: string;
    area: string;
    source: string;
}

export interface ParticipantsReport {
    participants: Participant[];
    total: number;
    averageAge: number;
    minAge: number;
    maxAge: number;
}

export abstract class ParticipantsReportProvider {
    abstract getReport(routeId: string, areaId: string, editionId: string, page: number, pageSize: number): Observable<ParticipantsReport>;
}
