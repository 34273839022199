import {FileInfo, RouteFileProvider} from '../data/edk/file';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable()
export class RouteFileProviderService extends RouteFileProvider {
    constructor(private http: HttpClient) {
        super();
    }

    fileInfos(routeId: string, type: string = ''): Observable<FileInfo[]> {
        return this.http.get<FileInfo[]>(API_URL + '/api/v1/route-file/infos/' + routeId,
            {params: {type: type}}).pipe(
            map(infos => {
                infos.forEach(info => info.link = API_URL + '/api/v1/route-file/download/' + info.id);
                return infos;
            }));
    }
}
