import {LearningResource, LearningResourceService} from '../data/edk/learning-resource';
import {Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

const API_URL = environment.apiUrl;

@Injectable()
export class LearningResourceServiceImpl extends LearningResourceService {
    constructor(private http: HttpClient) {
        super();
    }

    save(learningResource: LearningResource, file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('learningResource', JSON.stringify(learningResource));
        formData.append('file', file);
        const req = new HttpRequest('POST', API_URL + '/api/v1/learning-resource', formData, {
            reportProgress: true,
            responseType: 'json',
        });

        return this.http.request(req);
    }

    find(editionId: string, resourceType: string): Observable<any> {
        return this.http.get<any>(API_URL + '/api/v1/learning-resource/find',
            {
                params: {
                    editionId: editionId,
                    resourceType: resourceType,
                }
            });
    }

    delete(learningResourceId: string): Observable<any> {
        return this.http.delete<any>(API_URL + '/api/v1/learning-resource/' + learningResourceId);
    }

}
