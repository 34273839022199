import {Observable} from 'rxjs';

export namespace EditionStatus {
    export const DRAFT = 'DRAFT';
    export const ACTIVE = 'ACTIVE';
    export const CLOSED = 'CLOSED';
    export const REMOVED = 'REMOVED';
}

export interface Edition {
    id: string;
    name: string;
    eventDate: string;
    status: string;
    addons?: any;
}

export interface CreateEdkEditionCommand {
    srcEditionId: string;
    name: string;
    eventDate: string;
}

export interface UpdateEdkEditionStatusCommand {
    id: string;
    status: string;
}

export interface ResourceWithEdition {
    resourceId: string;
    edition: Edition;
}

export abstract class EditionCrud {
    abstract findCurrentEdition(): Observable<Edition>;
    abstract findAll(): Observable<Edition[]>;
    abstract findEdition(id: string): Observable<Edition>;
    abstract create(command: CreateEdkEditionCommand): Observable<Edition>;
    abstract checkEditionCreationFinished(): Observable<any>;
    abstract updateEditionStatus(command: UpdateEdkEditionStatusCommand): Observable<any>;
    abstract findHistory(resourceType: string, resourceId: string): Observable<ResourceWithEdition[]>;
}

export abstract class CurrentEditionService {
    abstract init();
    abstract fetchEditableEdition();
    abstract setEdition(edition: Edition);
    abstract currentEdition(): Observable<Edition>;
}
