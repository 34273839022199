import {
    EdkRoute,
    EdkRouteCrud,
    EdkRoutePage,
    RouteCharacteristicsWithVerificationStatus,
    RouteWithPath,
    UpdateRouteColorCommand,
    UpdateRouteStatusCommand
} from '../data/edk/route';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';


const API_URL = environment.apiUrl;

@Injectable()
export class EdkRouteCrudService extends EdkRouteCrud {
    constructor(private http: HttpClient) {
        super();
    }

    create(route: EdkRoute): Observable<EdkRoute> {
        return this.http.post<EdkRoute>(API_URL + '/api/v1/route', route);
    }

    update(route: EdkRoute): Observable<EdkRoute> {
        return this.http.put<EdkRoute>(API_URL + '/api/v1/route', route);
    }

    findAvailableStatuses(id: string): Observable<string[]> {
        return this.http.get<string[]>(API_URL + '/api/v1/route/' + id + '/available-statuses');
    }

    updateStatus(command: UpdateRouteStatusCommand): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/route/status', command);
    }

    updateColor(command: UpdateRouteColorCommand): Observable<any> {
        return this.http.put<any>(API_URL + '/api/v1/route/color', command);
    }

    deleteRoute(id: string): Observable<any> {
        return this.http.delete<any>(API_URL + '/api/v1/route/' + id);
    }

    findRoute(id: string): Observable<EdkRoute> {
        return this.http.get<EdkRoute>(API_URL + '/api/v1/route/' + id);
    }

    findRoutes(editionId: string, areaGroupId: string, areaId: string, q: string, statuses: string[],
               lengthMin: number = undefined, lengthMax: number = undefined,
               page: number, pageSize: number): Observable<EdkRoutePage> {
        return this.http.get<EdkRoutePage>(API_URL + '/api/v1/route', {
            params: {
                page: page,
                pageSize: pageSize,
                editionId: editionId,
                areaGroupId: areaGroupId,
                areaId: areaId,
                q: q,
                statuses: statuses,
                lengthMin: lengthMin != undefined ? lengthMin : '',
                lengthMax: lengthMax != undefined ? lengthMax : '',
            }
        });
    }

    findRoutesColors(areaId: string): Observable<string[]> {
        return this.http.get<string[]>(API_URL + '/api/v1/route/colors/' + areaId);
    }


    verifyKml(routeId: string, file: File): Observable<HttpEvent<RouteCharacteristicsWithVerificationStatus>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        const req = new HttpRequest('POST', API_URL + '/api/v1/route/' + routeId + '/verify-kml', formData, {
            reportProgress: true,
            responseType: 'json',
        });

        return this.http.request(req);
    }

    saveKml(routeId: string, file: File): Observable<HttpEvent<RouteCharacteristicsWithVerificationStatus>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        const req = new HttpRequest('POST', API_URL + '/api/v1/route/' + routeId + '/save-kml', formData, {
            reportProgress: true,
            responseType: 'json',
        });

        return this.http.request(req);
    }

    findRouteCharacteristics(id: string): Observable<RouteCharacteristicsWithVerificationStatus> {
        return this.http.get<RouteCharacteristicsWithVerificationStatus>(API_URL + '/api/v1/route/' + id + '/characteristics');
    }

    findRoutePathsByArea(areaId: string): Observable<RouteWithPath[]> {
        return this.http.get<RouteWithPath[]>(API_URL + '/api/v1/route/paths-by-area', {params: {areaId: areaId}});
    }


}
