import {ParticipantsReport, ParticipantsReportProvider} from '../data/edk/participant';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable()
export class ParticipantsReportProviderService extends ParticipantsReportProvider {
    constructor(private http: HttpClient) {
        super();
    }

    getReport(routeId: string, areaId: string, editionId: string, page: number, pageSize: number): Observable<ParticipantsReport> {
        return this.http.get<ParticipantsReport>(API_URL + '/api/v1/participant/report',
            {
                params: {
                    routeId: routeId,
                    areaId: areaId,
                    editionId: editionId,
                    page: page,
                    pageSize: pageSize}});
    }
}
