import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {map, takeUntil} from 'rxjs/operators';
import {RippleService} from '../../../@core/utils/ripple.service';
import {Subject} from 'rxjs';
import {TranslateService} from '../../../@core/lang/translate.service';
import {languages} from '../../../@core/lang/languages';

@Component({
    selector: 'ngx-footer',
    styleUrls: ['footer.component.scss'],
    templateUrl: 'footer.component.html',
})
export class FooterComponent implements OnInit, OnDestroy {
    constructor(private themeService: NbThemeService,
                private translateService: TranslateService,
                private rippleService: RippleService) {
    }

    readonly themes = [
        {
            value: 'dark',
            name: 'Dark',
        },
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'cosmic',
            name: 'Cosmic',
        },
        {
            value: 'corporate',
            name: 'Corporate',
        },
    ];

    currentTheme = 'cosmic';
    readonly LS_THEME_KEY = 'edkPanelTheme';

    readonly languages = languages;
    currentLang = 'pl';

    private destroy$: Subject<void> = new Subject<void>();

    changeTheme(themeName: string) {
        localStorage.setItem(this.LS_THEME_KEY, themeName);
        this.currentTheme = themeName;
        this.themeService.changeTheme(themeName);
    }

    changeLang(lang: string) {
        if (lang === this.currentLang) {
            return;
        }
        this.currentLang = lang;
        this.translateService.setLang(lang);
        window.location.reload();
    }

    ngOnInit(): void {
        this.themeService.onThemeChange()
            .pipe(
                map(({name}) => name),
                takeUntil(this.destroy$),
            ).subscribe(themeName => {
                this.rippleService.toggle(themeName?.startsWith('material'));
            });

        const theme = localStorage.getItem(this.LS_THEME_KEY) ? localStorage.getItem(this.LS_THEME_KEY) : 'cosmic';
        this.changeTheme(theme);

        this.translateService.lang$.subscribe(lang => this.currentLang = lang);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
